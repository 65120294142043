import React, { useEffect, useState } from "react";
import "./Auctions.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Slider from "./Slider";
import "./Auctions.css";
import FilterCategories from "./FilterCategories";

function Auctions() {
  const [itemList, setItemList] = useState([]);
  const [complete, setComplete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  let navigate = useNavigate();

  useEffect(() => {
    setItemList([]);
    setComplete(false);

    if (Object.keys(selectedCategory).length <= 0) {
      axios.get(`https://backgem.tfdatamaster.com/items`).then((res) => {
        setItemList(res.data);
        setComplete(true);
      });
    } else {
      axios
        .get(`https://backgem.tfdatamaster.com/items/categories/${selectedCategory.id}`)
        .then((res) => {
          setItemList(res.data);
          setComplete(true);
        });
    }
  }, [selectedCategory]);

  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(9);

  const handleChangeRows = (event) => {
    setItemsPerPage(event.target.value);
  };

  const visitedPages = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(itemList.length / itemsPerPage);

  const displayItems = itemList
    .slice(visitedPages, visitedPages + itemsPerPage)
    .map((value, key) => (

      
      <div
        className="Auctioniitem"
        key={key}
        onClick={() => {
          navigate(`/item/${value.id}`);
        }}
      >
        <div className="Auction-body">
       
          <img
            className="lando_image"
            src={value.coverPhoto}
            alt="coverphoto"
          />
        </div><div className="name">{value.name}</div>
        
        <div className="footer">
          <div>
            {value.location}, {value.country}
          </div>
        </div>
        <div className="card__price">{value.currently} €</div>

      </div>
    ));

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div>
      <Slider />
      <FilterCategories />

      {/* {Object.keys(selectedCategory).length > 0 ? (
        <h1 className="title">{selectedCategory.name}</h1>
      ) : (
        <h1 className="title">Shop by Category</h1>
      )} */}
      <div className="iteminpage">
        <div className="items-per-page">
          <label>Items/Page</label>
          <select value={itemsPerPage} onChange={handleChangeRows}>
            <option value={9}>9</option>
            <option value={18}>18</option>
            <option value={27}>27</option>
          </select>
        </div>
      </div>

      <div className="pauction">
        {itemList.length === 0 && complete && (
          <>
            <div className="no-results">
              <h4>No auctions found</h4>
            </div>
            <img
              src="https://indususedcars.com/assets/theme/images/no_result_found.png"
              alt="coverphoto"
            />
            <p className="header-text">
              Unfortunately, there are no current listings for{" "}
              {selectedCategory.name}
            </p>
          </>
        )}

        {itemList.length === 0 && !complete && (
          <>
            <div className="loading">
              <h4>Loading</h4>
            </div>
            {/* <img
                      alt="coverphoto"
                      src="https://i.pinimg.com/originals/f2/9f/02/f29f025c9ff5297e8083c52b01f1a709.gif"
                    /> */}
          </>
        )}
{/*  */}
        {itemList.length !== 0 && (
          <>
            <div className="grid-itemicontainerr">{displayItems}</div>
            <div className="pagenavigation">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Auctions;