import React from 'react';

function NotFound() {
  return (
    <>
    {/* <img alt='Not Found' src='https://cdni.iconscout.com/illustration/premium/thumb/404-error-3702345-3119136.png' /> */}
    </>
  )
}

export default NotFound;
