import React from "react";
import "./Body.css";

function Body(props) {
  return (
    <div>
      <div className="Disbody-container">{props.text}</div>
    </div>
  );
}

export default Body;
