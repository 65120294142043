import React from "react";
import "./Header.css";

function Header(props) {
  return (
    <div>
      <div className="custom-box">
        <h7 className="custom-text">{props.text}</h7>
      </div>
    </div>
  );
}

export default Header;
