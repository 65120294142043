import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import "./LoginForm.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 290,
  bgcolor: "background.paper",
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
};

function LoginForm() {
  let navigate = useNavigate();

  const initialValues = {
    username: "",
    password: "",
  };

  const { setAuthState } = useContext(AuthContext);

  const [isRegistered, setIsRegistered] = useState(true); // Check if the user is registered

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(3).max(20).required("You must input a username"),
    password: Yup.string().min(4).max(20).required("You must input a password"),
  });

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const [errorMessage, setErrorMessage] = React.useState("");

  const onSubmit = (data) => {
    if (!isRegistered) {
      // If the user is not registered, navigate to the registration page
      navigate("/Registration");
    } else {
      // Continue with login logic
      axios.post("https://backgem.tfdatamaster.com/auth/login", data).then((res) => {
        if (res.data.error) {
          if (res.data.error === "approval") {
            setOpen(true);
          } else {
            setErrorMessage(res.data.error);
            handleOpenAlert();
          }
        } else {
          localStorage.setItem("accessToken", res.data.token);
          setAuthState({
            username: res.data.username,
            id: res.data.id,
            status: true,
          });
          if (res.data.username === "admin") {
            navigate("/users");
          } else {
            navigate("/");
          }
        }
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form className="sign-in-form">
            <h2 className="title">Sign in</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <Field type="text" name="username" placeholder="Username" />
            </div>
            <ErrorMessage name="username" component="span" />

            <div className="input-field">
              <i className="fas fa-lock"></i>
              <Field type="password" name="password" placeholder="Password" />
            </div>
            <ErrorMessage name="password" component="span" />

            <button type="submit" className="btnrm solid">
              Login
            </button>
            
          </Form>
        )}
      </Formik>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Your Application is Pending Approval
          </Typography>

          <Typography variant="h6" id="modal-modal-description" sx={{ mt: 2 }}>
            You will be able to sign in and use our services, as soon as your
            profile has been seen and approved by the administrator!
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <div className="container">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {errorMessage}
            </Typography>
            <button className="buttonito" onClick={handleCloseAlert}>
              Close
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default LoginForm;
