import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import MessageCard from "./MessageCard";
import "./Mailoutbox.css";

import ReactPaginate from "react-paginate";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

export default function MailOutbox() {
  const [mailList, setMailList] = useState([]);
  const [messageDisplayed, setMessageDisplayed] = useState({});
  const [clicked, setClicked] = useState(false);
  const [newDeleted, setNewDeleted] = useState(false);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    const head = {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    };

    axios
      .get(`https://backgem.tfdatamaster.com/mail/outbox/${authState.id}`, head)
      .then((res) => {
        setMailList(res.data.slice(0).reverse());
      });

    // eslint-disable-next-line
  }, [newDeleted]);

  // To limit the amount of characters from the body
  const certainChars = (str) => {
    if (str.length > 75) {
      str = str.substring(0, 72);
      str += "...";
    }
    return str;
  };

  const displayMessage = (name) => {
    axios
      .get(`https://backgem.tfdatamaster.com/auth/fetchy/${name.senderId}`)
      .then((respo) => {
        name.name = respo.data.name;
        name.surname = respo.data.surname;

        setMessageDisplayed(name);
        setClicked(true);
      });
  };

  const deleteMessage = (name) => {
    axios
      .put(`https://backgem.tfdatamaster.com/mail/message/deleteoutbox/${name.id}`)
      .then((res) => {});
    let mails = [...mailList];

    for (var i = 0; i < mailList.length; i++) {
      if (mailList[i].id === name.id) {
        let mail = { ...mails[i] };
        mail.deletedOutbox = true;
        mails[1] = mail;
        setMailList(mails);
      }
    }

    if (newDeleted) {
      setNewDeleted(false);
    } else {
      setNewDeleted(true);
    }
  };

  // Pagination Information
  const [pageNumber, setPageNumber] = useState(0);
  const [mailPerPage, setMailPerPage] = useState(6);

  const handleChangeRows = (event) => {
    setMailPerPage(event.target.value);
  };

  const visitedPages = pageNumber * mailPerPage;
  const pageCount = Math.ceil(mailList.length / mailPerPage);

  // Displaying the items of this particular page
  const displayMail = mailList
    .slice(visitedPages, visitedPages + mailPerPage)
    .map((value, key) => {
      return (
        <div className="sentmailclass" onClick={() => displayMessage(value)}>
          <div class="comment-container">
            <br />{" "}
            <div class="user">
              <div class="user-pic">
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linejoin="round"
                    fill="#707277"
                    stroke-linecap="round"
                    stroke-width="2"
                    stroke="#707277"
                    d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                  ></path>
                  <path
                    stroke-width="2"
                    fill="#707277"
                    stroke="#707277"
                    d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
                  ></path>
                </svg>
              </div>
              <div class="user-info">
                <span>
                  {value.recipientName} {value.recipientSurname}
                </span>
                <p>{value.title}</p>
              </div>
            </div>
            <p class="comment-content">{certainChars(value.body)}</p>
            <button
              className="historypush"
              onClick={() => displayMessage(value)}
            >
              See More{" "}
            </button>
          </div>
        </div>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        {displayMail}
        <br/>

        <div style={{ width: 140, paddingLeft: 40 }}>
          <Box sx={{ minWidth: 50 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Mail per Page
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mailPerPage}
                label="mail"
                onChange={handleChangeRows}
                variant="standard"
              >
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={48}>48</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </Grid>
      <Grid item xs={7.6}>
          {clicked && (
            <div className="message-card">
              <MessageCard message={messageDisplayed} type="inbox" />
            </div>
          )}{" "}
        </Grid>

      <div className="pagenavigation"></div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationButtonsMail"}
        previousLinkClassName={"previousButtonMail"}
        nextLinkClassName={"nextButtonMail"}
        disabledClassName={"paginationDisabledMail"}
        activeClassName={"paginationActiveMail"}
      />
      {/* Mail per page */}
    </Grid>
  );
}
