import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MailInbox from "../components/Mail/MailInbox";
import MailOutbox from "../components/Mail/MailOutbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import InboxIcon from "@mui/icons-material/Inbox";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ReviewsIcon from "@mui/icons-material/Reviews";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MailChats from "../components/Mail/MailChats";
import MailPurchases from "../components/Mail/MailPurchases";
import Title from "../components/Typography/Title";
import MailMessage from "../components/Mail/MailMessage";
import "./MailToggle.css";

export default function MailToggle() {
  const [alignment, setAlignment] = React.useState("inbox");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <div className="mailcontainer">
        <ToggleButtonGroup
          color="success"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="message">
            <h4>NEW MESSAGE</h4>
          </ToggleButton>
          <ToggleButton value="inbox">
           <h4>INBOX</h4> 
          </ToggleButton>
          <ToggleButton value="outbox">
         <h4>Sent Mail </h4>
          </ToggleButton>
          <ToggleButton value="chats">
            <h4>SALES</h4>
          </ToggleButton>
          <ToggleButton value="reviews">
            <h4>PURCHASES</h4>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div
        style={{
          minHeight: "90vh",
          width: "90%",
          backgroundRepeat: "repeat",
          backgroundSize: "20%",
        }}
      >
        <div className="container">
          {alignment === "message" && <MailMessage />}
          {alignment === "inbox" && <MailInbox />}
          {alignment === "outbox" && <MailOutbox />}
          {alignment === "chats" && <MailChats />}
          {alignment === "reviews" && <MailPurchases />}
        </div>
      </div>
    </>
  );
}
