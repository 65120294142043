import React from "react";
import "gsap/CSSPlugin"; // Import GSAP CSSPlugin
import "./Slider.css";

function Slider() {
  return (
    <div>
      <div className="headerr">
        <div className="slider-boxr">
          <div className="carouselr">
            <div className="carousel-item it2">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/06/Untitled-37.jpg"
              />
            </div>
            <div className="carousel-item it3">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/07/New-Project131.jpg"
              />
            </div>
            <div className="carousel-item it4">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/06/Untitled-32.jpg"
              />
            </div>
            <div className="carousel-item it5">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/07/New-Project111.jpg"
              />
            </div>
            <div className="carousel-item it6">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/07/New-Project139.jpg"
              />
            </div>
            <div className="carousel-item it7">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/06/Untitled-AAAAAAAABBBBBBBB.jpg"
              />
            </div>
            <div className="carousel-item it8">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/06/hgk.png"
              />
            </div>
            <div className="carousel-item it9">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/07/New-Project123.jpg"
              />
            </div>
            <div className="carousel-item it10">
              <img
                className="img"
                // src="https://kingofasia.lk/wp-content/uploads/2023/07/New-Project139.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
