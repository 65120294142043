import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CountryDropdown } from "react-country-region-selector";
import Categories from "../components/Modals/Categories";
import CreateCoordinates from "../components/Maps/CreateCoordinates";
import ConvertDMS from "../components/Maps/ConvertDMS";
import "./CreateItem.css";
// Mui Components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function CreateItem() {
  let navigate = useNavigate();
  const [mycountry, setCountry] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [holdData, setHoldData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const today = new Date();

  const initialValues = {
    name: "",
    description: "",
    currently: "",
    location: "",
    number_of_bids: 0,
    country: "Greece",
    started: today,
    date: new Date(),
    furthermostCategoryId: 0,
  };

  const handleChange = (country) => {
    setCountry(country);
    initialValues.country = country;
  };

  const customTextArea = (props) => <textarea type="text" {...props} />;

  // validation of the fields
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).max(155).required("You must input a name"),
    description: Yup.string()
      .min(23)
      .max(4000)
      .required("You must input a description"),
    currently: Yup.number("You must input a number.")
      .required("You must input an initial price.")
      .test(
        "maxDigitsAfterDecimal",
        "This must have 2 digits after decimal or less",
        (number) => /^\d+(\.\d{1,2})?$/.test(number)
      ),
    buy_price: Yup.number().moreThan(
      Yup.ref("currently"),
      "This has to be greater than Starting Price"
    ),
    location: Yup.string()
      .min(3)
      .max(155)
      .required("You must input a location."),
    country: Yup.string()
      .min(3)
      .max(155)
      .required("You must input your country."),
    started: Yup.date().min(today, "Starting time can't be in the past."),
    ends: Yup.date()
      .required("You must input an ending time.")
      .min(Yup.ref("started"), "Ending time can't be before starting time."),
  });

  const onSubmit = (data) => {
    setHoldData(data);
    setOpenDialog(true);
  };

  useEffect(() => {
    // Then get the categories recursively and return them from parent to children
    axios
      .get(`https://backgem.tfdatamaster.com/categories/${selectedCategory.id}`)
      .then((rescateg) => {
        setCategories(rescateg.data);
      });
  }, [selectedCategory]);

  // these for the coordinates
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);

    const now = new Date();
    if (Date.parse(holdData.started) > Date.parse(now)) {
      holdData.state = "EXPECTED";
    }

    // if the user inputed coordinates add them too
    if (Object.keys(coordinates).length > 0) {
      var point = {
        type: "Point",
        coordinates: [coordinates.lat, coordinates.lng],
      };
      console.log(point);
      holdData.latitudeLongitude = point;
    }

    if (holdData.buy_price.length === 0) {
      holdData.buy_price = null;
    }

    holdData.first_bid = holdData.currently;
    holdData.country = mycountry;
    holdData.furthermostCategoryId = selectedCategory.id;
    const head = {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    };
    axios.post("https://backgem.tfdatamaster.com/items", holdData, head).then((res) => {
      if (res.data.error) {
        alert("You are not signed in!");
      } else {
        navigate(`/`);
      }
    });
  };

  return (
    <div className="createitempage">
      <div className="outerDiv">
        {/* <div className="createslider">
          <div class="idcontainer">
            <div class="row">
              <div class="col-md-1">
                <div class="icard card-1">
                  <h3>Write a standout title</h3>
                  <p>
                    We’ll recommend search terms that buyers often use, so be
                    sure to add these in the title.
                  </p>
                  <p>
                    Avoid all caps and focus on specific details like brand,
                    model, size, and color.
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="icard card-2">
                  <h3>Take high-quality photos</h3>
                  <p>
                    Snap your items from multiple angles in a well-lit place,
                    and capture any blemishes for transparency
                  </p>
                  <p>
                    On the eBay app, you can clean up your images and add a
                    white background.
                  </p>
                </div>
              </div>
              <div className="g3">
                <div class="col-md-3">
                  <div class="icard card-3">
                    <h3>Set the right price</h3>
                    <p>
                      We will recommend a price based on recent sales of similar
                      items.
                    </p>
                    <p>
                      You can even watch how other sellers are pricing their
                      items, or use Best Offer to negotiate and sell faster.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div> */}

        <div class="form-container">
          <p class="formtitle">Create Auction</p>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            onChange={handleChange}
            validationSchema={validationSchema}
          >
            <Form className="form">
              {" "}
              
              <Field
                type="text"
                class="input"
                id="inputCreateItem"
                name="name"
                placeholder="Item"
              />
              <ErrorMessage name="name" component="span" />
              <Field
                type="text"
                class="input"
                id="inputCreateItem"
                name="currently"
                placeholder="Starting Price"
              />
              <ErrorMessage name="currently" component="span" />
              <Field
                class="input"
                id="inputCreateItem"
                name="buy_price"
                placeholder="Buying Price"
              />
              <ErrorMessage name="buy_price" component="span" />
              <Field
                class="input"
                id="inputCreateItem"
                name="location"
                placeholder="Location"
              />
              <ErrorMessage name="location" component="span" />
              <CountryDropdown
                class="input"
                id="inputCreateItem"
                name="country"
                value={mycountry}
                onChange={(e) => handleChange(e)}
              />
              <ErrorMessage name="country" component="span" />
              <Field
                id="inputCreateItem"
                class="input"
                name="started"
                type="datetime-local"
              />
              <ErrorMessage name="started" component="span" />
              <Field
                id="inputCreateItem"
                class="input"
                name="ends"
                type="datetime-local"
              />
              <ErrorMessage name="ends" component="span" />
              <Field
                id="inputDescription"
                class="input"
                name="description"
                as="textarea"
                placeholder="Write a short description here"
              />
              <ErrorMessage name="description" component="span" />
              {/* <Detail
                text={categories.map((value, key) => {
                  return (
                    <div key={key}>
                      {value}
                      {key === categories.length - 1 ? "" : ","}&nbsp;
                    </div>
                  );
                })}
              /> */}
              <div class="input">
                <Categories setSelectedCategory={setSelectedCategory} />
              </div>
              <button type="submit" className="formt-btn">
                Add Item
              </button>
            </Form>
          </Formik>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{
                fontFamily: "Futura",
              }}
            >
              {"Optionally, you can also provide the precise location"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <CreateCoordinates setCoordinates={setCoordinates} />
                {Object.keys(coordinates).length > 0 && (
                  <div style={{ marginTop: "2rem" }}>
                    Set to: {ConvertDMS(coordinates.lat, coordinates.lng)}
                  </div>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                className="buttonito"
                onClick={handleCloseDialog}
                autoFocus
              >
                Continue
              </button>
            </DialogActions>
          </Dialog>

          {Object.keys(coordinates).length > 0 && (
            <div style={{ color: "#00C9FF" }}>
              Coordinates set to: {coordinates.lat}, {coordinates.lng}
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 

export default CreateItem;
